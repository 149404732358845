import axios, { AxiosResponse } from 'axios'
import {AuthModel, UserModel} from './_models'
import qs from 'qs'; 

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/auth/access-token`
export const LOGIN_URL = `${API_URL}/api/auth/access-token`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`


// Server should return AuthModel
export function login(email: string, password: string): Promise<AxiosResponse<AuthModel>> {
  const data = qs.stringify({
    grant_type: "",
    username: email,
    password: password,
    scope: "",
    client_id: "",
    client_secret: ""
  });

  return axios({
    method: 'post',
    url: LOGIN_URL,
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}


// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    access_token: token,
  })
}
