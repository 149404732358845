import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from '../../../modules/auth/core/AuthHelpers';

type Props = {
  className: string;
  description: string;
  color: string;
  img: string;
};

const ChannelsCard20: React.FC<Props> = ({ className, description, color, img }) => {
  const [channels, setChannels] = useState<any[]>([]);
  const [activeChannelsCount, setActiveChannelsCount] = useState<number>(0);

  useEffect(() => {
    const fetchChannels = async () => {
      const auth = getAuth();
      if (!auth || !auth.access_token) {
        console.error("Token not found!"); window.location.href = "/auth";
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tv_channel/`, {
          headers: {
            'Authorization': `Bearer ${auth.access_token}`,
          },
        });
        setChannels(response.data.Data);
        const activeCount = response.data.Data.filter((channel: any) => channel.status.name === "ACTIVE").length;
        setActiveChannelsCount(activeCount);
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    };

    fetchChannels();
  }, []);

  const totalChannels = channels.length;
  const activeChannelPercentage = totalChannels ? Math.round((activeChannelsCount / totalChannels) * 100) : 0;

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{totalChannels}</span>
          <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
        </div>
      </div>
      <div className='card-body d-flex align-items-end pt-0'>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
            <span>{activeChannelsCount} Active</span>
            <span>{activeChannelPercentage}%</span>
          </div>

          <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
            <div
              className='bg-white rounded h-8px'
              role='progressbar'
              style={{ width: `${activeChannelPercentage}%` }}
              aria-valuenow={activeChannelPercentage}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ChannelsCard20 };
